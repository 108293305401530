import data from './dividers.json';

export type Divider = {
  collection: string;
  file: string;
  key: string;
  attribution: string;
}

export const DIVIDERS = data.collections.flatMap(col =>
  col.images.map(img => ({ file: `dividers/${img}`, collection: col.name, attribution: col.attribution, key: img }))
) as Divider[];

export const BASIC_DIVIDER: Divider = {
  collection: 'nonse',
  attribution: 'none',
  key: 'basic-line',
  file: 'line.png'
}
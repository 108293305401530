import { useEffect, useState } from 'react';

import './App.css';
import { FONTS, BASIC_FONTS_BY_CAT, Font } from './Fonts';
import { CATS, Category } from './Category';
import { DIVIDERS, BASIC_DIVIDER, Divider } from './Divider';
import { ga } from './Ga';

import Card from './Card';

const formUrl = (formId: string) => `https://docs.google.com/forms/d/e/${formId}/formResponse`;


const FORM = {
  id: '1FAIpQLSclXjo4VYz3r1gHsqLiKyB-q6fkpLP9Iyx-bAVrH74qOLZQFw',
  fields: {
    divider: 'entry.716938171',
    font: 'entry.1790341452',
    cat: 'entry.959656079',
    value: 'entry.1853319038',
    type: 'entry.313289806',
  }
};

type FormField = keyof (typeof FORM)['fields'];

function sendForm(cfg: Record<FormField, string | number | undefined>) {
  const params = new URLSearchParams();
  for (const [k, v] of Object.entries(cfg) as [FormField, string | number | undefined][]) {
    params.set(FORM.fields[k], `${v}`);
  }

  let node: HTMLElement;

  const url = formUrl(FORM.id);

  if (/Trident\/|MSIE/.test(window.navigator.userAgent)) {
    let img = node = document.createElement('img');
    img.src = `${url}?${params.toString()}`;
  } else if (/Firefox/.test(window.navigator.userAgent)) {
    let obj = node = document.createElement('object');
    obj.data = `${url}?${params.toString()}`;
  } else {
    let link = node = document.createElement('link');
    link.href = `${url}?${params.toString()}`;
    link.as = 'fetch';
    link.rel = 'preload';
  }

  document.body.append(node);


  node.onload = (e) => {
    document.body.removeChild(node);
  }
}

function rnd<T>(arr: T[]) {
  return arr[Math.trunc(Math.random() * arr.length)];
}

function App() {
  const [cat, setCat] = useState<Category>();
  const [font, setFont] = useState<Font>();
  const [divider, setDivider] = useState<Divider>();
  const [type, setType] = useState<'divider' | 'font'>();

  function randomize() {
    const cat = rnd(CATS);
    setCat(cat);
    if (Math.random() > .5) {
      setType('font');
      setFont(rnd(FONTS));
      setDivider(BASIC_DIVIDER);
    } else {
      setType('divider');
      setDivider(rnd(DIVIDERS));
      setFont(BASIC_FONTS_BY_CAT[cat.name])
    }
  }

  useEffect(() => { if (!cat) setTimeout(randomize, 100); });

  function vote(value: number = 0) {
    if (!divider || !cat || !font) {
      return;
    }
    console.log('Voting', { divider, font, cat, value, type });
    sendForm({ type, divider: divider.key, font: font.family, cat: cat.name, value });
    ga.gtag('event', 'vote', {
      font: font.family,
      divider: divider.key,
      category: cat.name,
      value: `${value}`,
      type
    });
    setTimeout(randomize, 100);
  }

  return (
    (divider && cat && font && type) ? <Card type={type} divider={divider} cat={cat} font={font} vote={vote}></Card> : <div></div>
  );
}

export default App;

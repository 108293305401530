import { useState } from 'react';
import './App.css';
import { Category } from './Category';
import { Divider } from './Divider';
import { Font } from './Fonts';

function Card({ cat, font, vote, divider, type }: { divider: Divider, cat: Category, font: Font, type: 'font' | 'divider', vote: (valid: number) => void }) {

  const [ready, setReady] = useState(false);

  const fontFace = new FontFace(font.family, `url(${font.files.regular.replace(/^https?:/, '')})`);
  document.fonts.add(fontFace);
  fontFace.load().then(() => setReady(true));

  function doVote(val: number) {
    vote(val);
    // setReady(false);
  }

  return (
    <div className={["Card", type].join(' ')}>
      <div className="Category">{cat.name} {type}</div>
      <div className="Content">
        <h1 className="Card-title" style={{ fontFamily: font.family, opacity: ready ? 1 : 0 }}>
          {cat.title}
        </h1>
        <img src={divider.file} alt="divider" />
        <p style={{ opacity: ready ? 1 : 0 }}>{cat.text}</p>
      </div>
      <div className="buttons">
        <button onClick={doVote.bind(null, -5)}>😧</button>
        <button onClick={doVote.bind(null, -3)}>🙁</button>
        <button onClick={doVote.bind(null, 0)}>😐</button>
        <button onClick={doVote.bind(null, 3)}>🙂</button>
        <button onClick={doVote.bind(null, 5)}>😀</button>
      </div>
    </div >
  );
}

export default Card;

import fonts from './google-fonts.json';

export type Font = {
  family: string;
  variants: string[];
  subsets: string[];
  category: 'sans-serif' | 'serif' | 'display' | 'handwriting';
  files: Record<string, string>;
}

export const FONTS = fonts.items.filter(x => x.subsets.includes('latin')).filter(x => x.category === 'display' || x.category === 'handwriting') as Font[];

function getFont(name: string) {
  return FONTS.find(f => f.family === name)!;
}

export const BASIC_FONTS_BY_CAT: Record<string, Font> = {
  "fantasy": getFont("Caesar Dressing"),
  "scifi": getFont("Gugi"),
  "crime": getFont("Special Elite"),
  "historical": getFont("Seaweed Script"),
  "adventure": getFont("Bungee Shade"),
  "romance": getFont("Sail"),
  "religion": getFont("Cinzel Decorative"),
  "biography (memoir)": getFont("Lemonada"),
  "personal development": getFont("Passion One"),
  "classic": getFont("Forum"),
  "happy": getFont("Grandstander"),
  "sad": getFont("Lancelot"),
  "funny": getFont("Fontdiner Swanky"),
  "serious": getFont("Luxurious Roman"),
  "optimistic": getFont("Ceviche One"),
  "bleak": getFont("Love Ya Like A Sister"),
  "suspenseful": getFont("Yeseva One"),
  "calm": getFont("Whisper"),
  "scary": getFont("Eater"),
  "mysterious": getFont("Bangers"),
  "whimsical": getFont("Henny Penny"),
  "proper": getFont("Berkshire Swash"),
  "creepy": getFont("Creepster"),
};